import React, { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useLocation,
} from 'react-router-dom';
import Login from './pages/LoginPage/LoginPage';
import Board from './pages/BoardPage/BoardPage';
import BoardDetail from './pages/DetailPage/BoardDetailPage';

const App = () => {
	function ScrollToTop() {
		const { pathname } = useLocation();
		useEffect(() => {
			window.scrollTo(0, 0);
		}, [pathname]);
		return null;
	}

	return (
		<Router>
			<ScrollToTop />
			<div id='App'>
				<Switch>
					<Route exact path='/' component={Board} />
					<Route exact path='/detail/:boardId' component={BoardDetail} />
					<Route exact path='/login' component={Login} />
				</Switch>
			</div>
		</Router>
	);
};

export default App;
