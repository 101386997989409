import axios from 'axios';
import { ADDRESS } from '../config';

const instance = axios.create({
	// proxy: `${ADDRESS}`,
	// baseURL: '/company',
	baseURL: `${ADDRESS}/company`,
});

const errorMessage = () => {
	window.alert('서버가 응답하지 않습니다. 잠시 후 다시 시도해주세요.');
};

export const get_company = async () => {
	return await instance.get('/').catch(errorMessage);
};

export const update_company = async (data) => {
	return await instance.put('/', data).catch(errorMessage);
};
