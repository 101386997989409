import axios from 'axios';
import { ADDRESS } from '../config';

const instance = axios.create({
	// proxy: `${ADDRESS}`,
	// baseURL: '/board',
	baseURL: `${ADDRESS}/board`,
});

const errorMessage = () => {
	window.alert('서버가 응답하지 않습니다. 잠시 후 다시 시도해주세요.');
};

export const login = async (data) => {
	return await instance.post('/login', data).catch(errorMessage);
};

export const get_detail = async (id) => {
	return await instance.get(`/open/?id=${id}`).catch(errorMessage);
};

export const get_list = async (type) => {
	return await instance.get(`/?type=${type}`).catch(errorMessage);
};

export const delete_list = async (data) => {
	return await instance.post('/delete', data).catch(errorMessage);
};
