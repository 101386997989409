// Date Obj => [ 연, 월, 일, 시간, 분 ]
export const dateTransform = (date) => {
	date = new Date(date);
	let text;
	let arr = [
		date.getFullYear(),
		date.getMonth() + 1,
		date.getDate(),
		date.getHours(),
		date.getMinutes(),
	];
	if (arr[4] !== 0 && arr[3] < 10) {
		text = `${arr[0]}-${arr[1]}-${arr[2]} 0${arr[3]}:${arr[4]}`;
	} else if (arr[4] !== 0 && arr[4] < 10) {
		text = `${arr[0]}-${arr[1]}-${arr[2]} ${arr[3]}:0${arr[4]}`;
	} else if (arr[4] !== 0 && arr[3] >= 10 && arr[4] >= 10) {
		text = `${arr[0]}-${arr[1]}-${arr[2]} ${arr[3]}:${arr[4]}`;
	} else {
		text = `${arr[0]}-${arr[1]}-${arr[2]} ${arr[3]}:00`;
	}
	return text;
};
