import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { login } from '../../controller/board';
import { get_company } from '../../controller/company';

const LoginPage = () => {
	const history = useHistory();
	const [password, setPassword] = useState('');
	const [firm, setFirm] = useState('');
	useEffect(() => {
		get_company().then((res) => {
			setFirm(res.data.company.name);
		});
	}, []);

	const onEnter = (e) => {
		if (e.key === 'Enter') {
			onSubmit();
		}
	};

	const onSubmit = () => {
		const Data = { password: password };
		login(Data).then((res) => {
			if (res.data.success) {
				alert('로그인 되었습니다');
				history.push({ pathname: '/', state: 'success' });
			} else {
				alert('비밀번호를 확인해주세요');
			}
		});
	};

	return (
		<div className='board'>
			<div className='login'>
				<h3>{`(주)${firm} 관리자 페이지`}</h3>
				<h4>{`비밀번호 분실 시,\n서버 관리자에게 문의주세요.`}</h4>
				<input
					type='password'
					placeholder='비밀번호 입력'
					onKeyDown={onEnter}
					onChange={(e) => {
						setPassword(e.target.value);
					}}
				/>
				<button onClick={onSubmit}>확인</button>
			</div>
		</div>
	);
};

export default withRouter(LoginPage);
