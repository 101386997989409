import React, { useEffect, useState } from 'react';
import { firmArr } from '../data/data';
import { get_company, update_company } from '../controller/company';

const Firm = () => {
	const [curFirm, setCurFirm] = useState('');
	const [newFirm, setNewFirm] = useState('');
	const [curBusinessNumber, setCurBusinessNumber] = useState('');
	const [newBusinessNumber, setNewBusinessNumber] = useState('');
	const [curAddress, setCurAddress] = useState('');
	const [newAddress, setNewAddress] = useState('');
	const [password, setPassword] = useState('');

	useEffect(() => {
		get_company().then((res) => {
			console.log(res.data);
			setCurFirm(res.data.company.name);
			setCurBusinessNumber(res.data.company.businessNumber);
			setCurAddress(res.data.company.address);
		});
	}, []);

	const firmList = firmArr.map((el, idx) => (
		<li key={idx}>
			<p>{el}</p>
			<input
				type={idx === 3 ? 'password' : 'text'}
				placeholder={
					idx === 0
						? `현재:${curFirm}`
						: idx === 1
						? `현재:${curBusinessNumber}`
						: idx === 2
						? `현재:${curAddress}`
						: null
				}
				onChange={(e) => {
					idx === 0
						? setNewFirm(e.target.value)
						: idx === 1
						? setNewBusinessNumber(e.target.value)
						: idx === 2
						? setNewAddress(e.target.value)
						: setPassword(e.target.value);
				}}
			/>
		</li>
	));

	const onSubmit = () => {
		const Data = {
			name: newFirm ? newFirm : curFirm,
			address: newAddress ? newAddress : curAddress,
			businessNumber: newBusinessNumber
				? newBusinessNumber
				: curBusinessNumber,
			password: password,
		};
		console.log(Data);
		update_company(Data).then((res) => {
			if (res.data.success) {
				setCurFirm(res.data.name);
				setCurFirm('');
				setCurAddress('');
				setCurBusinessNumber('');
				window.location.reload();
				alert('정보가 변경되었습니다.');
			} else {
				return alert('입력하신 정보를 확인해주세요.');
			}
		});
	};

	return (
		<div className='contents'>
			<div className='firm'>
				<h4>상호명은 "(주)" 를 제외한 상호명만 입력해주세요</h4>
				<h4>비밀번호는 필수로 입력해주세요</h4>
				<ul>{firmList}</ul>
			</div>
			<button onClick={onSubmit}>변경하기</button>
		</div>
	);
};

export default Firm;
