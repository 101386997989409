import React, { useEffect, useRef, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { get_list, delete_list } from '../../controller/board';
import { menuArr, select, unselect } from '../../data/data';
import { dateTransform } from '../../functions/dataTransform';
import Firm from '../../components/Company';
import Check from '../../images/check-square.svg';
import UnCheck from '../../images/uncheck-square.svg';

const BoardPage = () => {
	const history = useHistory();
	const list = useRef();
	const pageList = useRef();
	const [number, setNumber] = useState(0);
	const [questionList, setQuestionList] = useState([]);
	const [questionPages, setQuestionPages] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [checked, setChecked] = useState([]);
	const [allCheck, setAllCheck] = useState(false);
	// eslint-disable-next-line
	const [login, setLogin] = useState(false);
	const [menu, setMenu] = useState('전체');
	const arrowArr = ['<', '>'];

	const menuTabs = menuArr.map((el, idx) => (
		<li
			key={idx}
			style={el === menu ? select : unselect}
			onClick={() => {
				setMenu(el);
			}}>
			{el}
		</li>
	));
	useEffect(() => {
		setChecked([]);
		if (menu === '전체' || menu === '열람' || menu === '미열람') {
			let type;
			if (menu === '전체') {
				type = '%';
			} else if (menu === '열람') {
				type = true;
			} else {
				type = false;
			}
			setCurrentPage(1);
			get_list(type).then((res) => {
				if (res.data.success) {
					setNumber(res.data.list.length);
					if (res.data.list.length % 7 !== 0) {
						let list = res.data.list;
						while (list.length % 7 !== 0) {
							list.push({});
						}
						setQuestionList(list);
					} else {
						setQuestionList(res.data.list);
					}
				}
			});
		}
	}, [menu]);

	useEffect(() => {
		if (history.location.state === 'success') {
			setLogin(true);
		} else {
			history.push('/login');
		}
	}, [history]);

	useEffect(() => {
		if (questionList) {
			getPages(questionList);
		}
	}, [questionList]);

	const getPages = (list) => {
		let arr = [];
		for (let i = 0; i < list.length / 7; i++) {
			arr.push(i + 1);
		}
		setQuestionPages(arr);
	};

	const pageMove = (button) => {
		setChecked([]);
		if (button === '<') {
			if (currentPage < 6) {
				setCurrentPage(1);
				pageUp();
			} else {
				setCurrentPage(currentPage - 5);
				pageUp();
			}
		} else if (button === '>') {
			if (currentPage === questionPages.length) {
				return;
			} else if (currentPage >= questionPages.length - 5) {
				setCurrentPage(questionPages.length);
				pageDown();
			} else {
				setCurrentPage(currentPage + 5);
				pageDown();
			}
		} else {
			setCurrentPage(button);
		}
	};

	const pageUp = () => {
		setChecked([]);
		const box = pageList.current;
		const boxHeight = box.offsetHeight;
		box.scrollTo({
			top: -(boxHeight * 2) + Math.ceil(currentPage / 5) * boxHeight,
		});
	};
	const pageDown = () => {
		setChecked([]);
		const box = pageList.current;
		const boxHeight = box.offsetHeight;
		box.scrollTo({
			top: Math.ceil(currentPage / 5) * boxHeight,
		});
	};

	const onAllCheck = (currentPage) => {
		let arr = [];
		setAllCheck(!allCheck);
		if (
			// 체크된 갯수가 7이거나
			// 마지막 페이지이면서 모두 체크된 경우
			checked.length === 7 ||
			(currentPage === questionPages.length && checked.length === number % 7)
		) {
			setChecked([]);
		} else if (
			currentPage !== questionPages.length ||
			(currentPage === questionPages.length && number % 7 === 0)
		) {
			// 마지막 페이지가 아닌 경우
			// 혹은 마지막 페이지이면서 목록의 갯수가 7인 경우
			for (let i = 0; i < 7; i++) {
				arr.push(questionList[(currentPage - 1) * 7 + i].id);
			}
		} else if (currentPage === questionPages.length && number % 7 !== 0) {
			// 마지막 페이지이면서, 목록의 갯수가 7이 아닌 경우
			for (let i = 0; i < number % 7; i++) {
				arr.push(questionList[(currentPage - 1) * 7 + i].id);
			}
		}
		setChecked(arr);
	};

	useEffect(() => {
		let arr = checked;
		let newArr = [...new Set(arr)];
		if (checked.length === 0) {
			setAllCheck(false);
		} else if (arr.length !== newArr.length) {
			// 원래의 배열과 중복 제거를 한 배열의 길이가 다를 때
			// (중복이 존재할 때)
			let double = arr.pop();
			setChecked(arr.filter((v) => v !== double));
		}

		if (currentPage !== questionPages.length && checked.length === 7) {
			// 마지막 페이지가 아니면서 체크된 항목이 7개 일 경우
			setAllCheck(true);
		} else if (currentPage === questionPages.length) {
			// 마지막 페이지이면서,
			if (number % 7 !== 0 && checked.length === number % 7) {
				// 목록의 갯수가 7개가 아니고 모두 체크된 경우
				setAllCheck(true);
			} else if (number % 7 === 0 && checked.length === 7) {
				// 목록의 갯수가 7개이면서 모두 체크된 경우
				setAllCheck(true);
			} else {
				setAllCheck(false);
			}
		} else {
			return setAllCheck(false);
		}
	}, [checked, currentPage, number, questionPages.length]);

	useEffect(() => {
		if (list.current) {
			const box = list.current;
			const boxHeight = list.current.offsetHeight;
			if (typeof currentPage === 'number') {
				box.scrollTo({
					top: (currentPage - 1) * boxHeight,
				});
			}
		}
	}, [currentPage]);

	const header = (
		<li className='header'>
			<img
				alt='체크'
				onClick={() => {
					onAllCheck(currentPage);
				}}
				src={allCheck ? Check : UnCheck}
			/>
			<p className='order'>등록번호</p>
			<p className='phone'>연락처</p>
			<p className='title'>제목</p>
			<p className='date'>날짜</p>
		</li>
	);

	const questions = questionList.map((el, idx) => (
		<li key={idx} className='list'>
			{el.id && (
				<img
					alt='체크'
					src={checked.includes(el.id) ? Check : UnCheck}
					onClick={() => {
						setChecked([...checked, el.id]);
					}}
				/>
			)}

			<p className='order'>{el.id}</p>
			<p className='phone' maxLength='30'>
				{el.contact}
			</p>
			<p
				className='title'
				maxLength='50'
				onClick={() => {
					history.push({
						pathname: `/detail/${el.id}`,
						state: {
							id: el.id,
							title: el.title,
							text: el.text,
							contact: el.contact,
							createdAt: el.createdAt,
						},
					});
				}}>
				{el.title}
			</p>
			<p className='date'>{el.createdAt && dateTransform(el.createdAt)}</p>
		</li>
	));

	const pages = questionPages.map((el, idx) => (
		<li
			key={idx}
			style={el === currentPage ? { color: '#bebebe' } : null}
			onClick={() => {
				pageMove(el);
			}}>
			{el}
		</li>
	));

	const onDelete = () => {
		const Data = { checked };
		if (checked.length === 0) {
			return alert('선택된 항목이 없습니다.');
		} else {
			delete_list(Data).then((res) => {
				if (res.data.success) {
					alert('삭제되었습니다.');
					setAllCheck(false);
					if (res.data.list.length % 7 !== 0) {
						let list = res.data.list;
						while (list.length % 7 !== 0) {
							list.push({});
						}
						setQuestionList(list);
					} else {
						setQuestionList(res.data.list);
					}
				}
			});
		}
	};

	return (
		<div className='board'>
			<div className='wrap'>
				<ul className='tab'>{menuTabs}</ul>
				{menu === '상호' ? (
					<Firm />
				) : (
					<div className='contents'>
						{header}
						<ul className='questions' ref={list}>
							{questions}
						</ul>
						<div>
							<ul className='pages' ref={pageList}>
								{pages}
							</ul>
							{arrowArr.map((el, idx) => (
								<li
									key={idx}
									className={idx === 0 ? 'left' : 'right'}
									onClick={() => {
										pageMove(el);
									}}>
									{el}
								</li>
							))}
						</div>

						<button onClick={onDelete}>삭제하기</button>
					</div>
				)}
			</div>
		</div>
	);
};

export default withRouter(BoardPage);
