import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { get_detail } from '../../controller/board';
import { dateTransform } from '../../functions/dataTransform';
import { itemArr } from '../../data/data';

const BoardDetailPage = () => {
	const history = useHistory();
	const { boardId } = useParams();
	const [id, setId] = useState('');
	const [title, setTitle] = useState('');
	const [createdAt, setCreatedAt] = useState('');
	const [contact, setContact] = useState('');
	const [text, setText] = useState('');

	useEffect(() => {
		get_detail(boardId);
		setId(history.location.state.id);
		setTitle(history.location.state.title);
		setCreatedAt(dateTransform(history.location.state.createdAt));
		setContact(history.location.state.contact);
		setText(history.location.state.text);
	}, [boardId, history.location.state]);

	return (
		<div className='board'>
			<div className='wrap'>
				<ul className='tab'>
					<li className='tab-detail'>상세보기</li>
				</ul>
				<div className='contents'>
					<ul className='detail'>
						{itemArr.map((el, idx) => (
							<li key={idx}>
								<p>{el}</p>
								<p>
									{idx === 0
										? id
										: idx === 1
										? title
										: idx === 2
										? createdAt
										: idx === 3
										? contact
										: idx === 4
										? text
										: null}
								</p>
							</li>
						))}
					</ul>
					<button
						onClick={() => {
							history.goBack();
						}}>
						목록으로
					</button>
				</div>
			</div>
		</div>
	);
};

export default withRouter(BoardDetailPage);
